import * as React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link } from "gatsby";

const Title = (props) => {
  return (
    <WrapBoxTypo
      fs="26px"
      fw="700"
      ls="5px"
      display="flex"
      justifyContent="center"
    >
      {props.title}
    </WrapBoxTypo>
  );
};

const Description = (props) => {
  return (
    <Box sx={{ mb: ["30px", "0"] }} display="flex" justifyContent="center">
      <WrapBoxTypo fs="14px" color="#515D59" ls="1px" lh="34px">
        {props.description}
      </WrapBoxTypo>
    </Box>
  );
};

const HoverText = styled(Box)`
  &:hover {
    opacity: 0.6;
  }
`;

const FormLinkText = (props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Link to={props.link} style={{ textDecoration: "none" }}>
        <HoverText display="flex" alignItems="center">
          <WrapBoxTypo fs="14px" color="#515D59" ls="1px">
            {props.text}
          </WrapBoxTypo>
          <Box width="5px" height="10px" ml="10px">
            <StaticImage
              alt="img"
              src="../images/vector.png"
              placeholder="blurred"
              quality="100"
            />
          </Box>
        </HoverText>
      </Link>
    </Box>
  );
};

const Recruit = () => {
  return (
    <Box mx="20px" mt={"180px"}>
      <Box
        sx={{
          maxWidth: "1100px",
          margin: ["0 20px 120px", "0 auto 200px"],
          border: "1px solid #747D7A",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ margin: ["60px 0", "50px 0 80px"] }} width={"100%"}>
          <Box sx={{ mb: ["30px", "20px"] }}>
            <Title title="Recruit" />
          </Box>
          <Box
            sx={{
              mb: ["20px", "30px"],
              width: "90%",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <WrapBoxTypo fs="14px" color="#515D59" ls="1px" lh="34px">
              forucafeではスタッフを募集しています。
              <br />
              以下よりご応募お待ちしております。
            </WrapBoxTypo>
          </Box>

          <FormLinkText
            text="応募フォームへ"
            link="https://forustyle.com/recruit/"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Recruit;
